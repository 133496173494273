import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'

import Badge from 'react-bootstrap/Badge'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import Image from './image'

import { useSitePages } from 'utils/use-site-pages'

import './work-catalog.scss'

export default function WorkCatalog() {
  const pageNodes = useSitePages()
  const [workList, setWorkList] = useState([])

  useEffect(() => {
    const buf = []
    pageNodes?.forEach((pageNode) => {
      const { pageContext, path: pageRoute } = pageNode ?? {}
      const { frontmatter: fm, projectData } = pageContext ?? {}
      if (fm?.catalog && fm?.poster && pageContext?.type === 'work') {
        buf.push(
          Object.assign(
            {},
            { pageRoute, imagePath: `${projectData?.contentKey}/${fm?.poster.name}` },
            projectData
          )
        )
      }
    })
    buf.sort((a, b) => a.title.localeCompare(b.title))
    setWorkList(buf)
  }, [])

  return (
    <Row id="work-catalog" className="g-2 justify-content-center text-center">
      {workList.map((work, workIndex) => (
        <Col lg={4} key={workIndex} style={{ maxWidth: '400px' }}>
          <Card>
            <Card.Header className="bg-body">
              <div className="catalog-item-title me-auto">
                <Link to={`${work.pageRoute}`}>{work.title}</Link>
              </div>
              {work.year && <Badge className="border border-light">{work.year}</Badge>}
            </Card.Header>
            <Card.Body className="catalog-item-body">
              <Image imagePath={work.imagePath} alt={work.title} />
              <Row className="p-2 g-1 justify-content-center">
                {work.pages?.map((item, pageIndex) => (
                  <Col key={`${workIndex}_${pageIndex}`} className="flex-grow-0 text-nowrap">
                    <Button size="sm" href={`${item.route}`}>
                      {item.label ?? 'Video'}
                    </Button>
                  </Col>
                ))}
              </Row>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
  )
}
