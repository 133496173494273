import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import Meta from 'components/meta'
import WorkCatalog from 'components/work-catalog'
import Wrapper from 'components/wrapper'

export default function CatalogPage({ location }) {
  return (
    <Wrapper
      location={location}
      variant="project"
      bodyClass="bg-body-darker"
      wrapperClass="d-flex flex-column w-100 mx-auto"
    >
      <Meta title="Catalog" />
        <Row className="my-3 align-items-center border-bottom border-secondary">
          <Col className="fs-3 media-title text-center">
            Project Catalog
          </Col>
        </Row>
      <WorkCatalog />
    </Wrapper>
  )
}
