import { graphql, useStaticQuery } from 'gatsby'

export const useSitePages = () => {
  const { allSitePage } = useStaticQuery(graphql`
    query SitePageQuery {
      allSitePage {
        nodes {
          pageContext
          path
        }
      }
    }
  `)
  return allSitePage.nodes
}
